import React from "react"
import { Link, graphql } from "gatsby"

import SEO from "../components/seo"
import _ from "lodash"
import "./css/article.css"

import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import Meta from "../components/meta";
import ProjectFooter from "../components/projectFooter"
import ArticleFooter from "../components/articleFooter"

class ArticlePage extends React.Component {
  render() {      
    const data = this.props.data.allContentfulArticle
    const article = data.edges[0].node

    const ImageComponent = ({ src, description, title }) => (      
      <div className="contentImage">
        <img title={title} src={ src } />
        <span className="imageDescription">
          { description }
        </span>      
      </div>
    )

    const options = {
      renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: (node) => {
          let path = node.data.target.fields 
          let url = path.file['en-US'].url
          let description = path.description['en-US']
          let title = path.title['en-US']          
          return <ImageComponent title={title} src={url} description={description}/>
        }
      }
    }

    const content = documentToReactComponents(data.edges[0].node.content.json, options)
    const intro = data.edges[0].node.intro.internal.content
    const date = article.date.split('-')
    const timeToRead = data.edges[0].node.content.childContentfulRichText.timeToRead
    const date_fixed = date[2] + "." + date[1] + "." + date[0]

    return (
      <div className="contentWrapper">
        <div className="article-page">          
          <Meta></Meta>                      
          <div className="nav">
              <AniLink to="/">
                  <i className="material-icons">
                      arrow_back
                  </i>
              </AniLink>
          </div>
          <div className="home">
            <Link to="/">
              Philipp Kaltofen
            </Link>
          </div>
          <h1 className="headline">
              { article.title }
          </h1>            
          <span className="date">
              <span>
                { date_fixed }
              </span>
              <span className="timeToRead">
                { timeToRead } min read
              </span>
          </span>
          <div className="intro">
            { intro }
          </div>
          <div className="content">
              { content }
          </div>          
        </div>  
      </div>
    )
  }
}

export default ArticlePage

export const pageQuery = graphql`
query articleQuery($title: String!){
  allContentfulArticle(filter:{title:{eq:$title}}) {
    nodes {      
      title
      date
      intro {
        internal {
          content
        }
      }
      content {
        json 
      }    
    }
    edges {
      node {      
        title
        date
        childContentfulArticleContentRichTextNode {
          json
        }
        intro {
          internal {
            content
          }
        }
        content {
          childContentfulRichText {
            timeToRead
          }
          json 
        }    
      }
    }
  }
}

`