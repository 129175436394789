import React from "react"
import "./css/footer.css"
import Link from 'gatsby-plugin-transition-link'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import AniLink from "gatsby-plugin-transition-link/AniLink"

class ProjectFooter extends React.Component {
    constructor(props){
        super(props)

        this.title = props.title
    }

    render() {    
        return (
        <div className="footer-project-wrapper">   
            <AniLink to="/" cover bg="#f1f1f1" direction="right">
                <div className="content left">                                
                    <div className="material-icons">
                        keyboard_arrow_left
                    </div>
                    <div className="tag">
                        BACK
                    </div>
                </div>   
            </AniLink>   
            <AniLink to={'/' + this.title + '/info'} swipe duration={0.2} bg="#f1f1f1" direction="left">
                <div className="content right">
                    <div className="tag">
                        Infos                    
                    </div>
                    <div className="material-icons">
                        keyboard_arrow_right
                    </div>
                </div>        
            </AniLink>
        </div>
        )
    }
}

export default ProjectFooter