import React from "react"
import "./css/footer.css"
import Link from 'gatsby-plugin-transition-link'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import AniLink from "gatsby-plugin-transition-link/AniLink"

class ArticleFooter extends React.Component {
    render() {    
        return (
        <div className="footer-article-wrapper">   
            <AniLink to="/" cover bg="#f1f1f1" direction="right">
                <div className="content left">                                
                    <div className="material-icons">
                        keyboard_arrow_left
                    </div>
                    <div className="tag">
                        BACK
                    </div>
                </div>   
            </AniLink>     
            <div className="content right">
                <div className="tag">
                    NEXT
                </div>
                <div className="material-icons">
                    keyboard_arrow_right
                </div>
            </div>        
        </div>
        )
    }
}

export default ArticleFooter