import React from "react"
import { Helmet } from "react-helmet"

class Meta extends React.Component {
    render() {
        return (
            <Helmet>
                <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"></link>
                <link rel="stylesheet" href="https://use.typekit.net/tmf1mhe.css"></link>
            </Helmet>
        )
    }
}

export default Meta